import React from 'react';
import news from "../../../assets/news.png";

const Newscard = () => {
  return (
    <div className='mx-5 py-20 flex justify-center '>
      <div className="grid lg:grid-cols-3 px-5 gap-5 cursor-pointer">
        
        <div className="max-w-sm bg-white border text-black  border-gray-200 shadow  rounded-2xl hover:bg-gradient-to-b from-[#002366] to-[#590929] hover:text-white">
       
            <img className="rounded-t-lg" src={news} alt="" />
        <a href='/newsdetails'>
          <div className="p-5 ">
       
              <h5 className="mb-2 text-2xl font-bold tracking-tight  ">
                New plugins for creating new charts in dashboard
              </h5>
           
            <p className="mb-3 font-normal">
              Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.
            </p>
            <a
              href="/newsdetails"
              className="inline-flex items-center px-3 py-2 text-sm font-medium text-center  border-2 border-white rounded-lg bor focus:ring-4 focus:outline-none "
            >
              Read more
              <svg
                className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2" 
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </a>
          </div>
          </a>
        </div>

        <div className="max-w-sm bg-white border text-black  border-gray-200 shadow  rounded-2xl hover:bg-gradient-to-b from-[#002366] to-[#590929] hover:text-white">
       6
            <img className="rounded-t-lg" src={news} alt="" />
        <a href='/newsdetails'>
          <div className="p-5 ">
       
              <h5 className="mb-2 text-2xl font-bold tracking-tight  ">
                New plugins for creating new charts in dashboard
              </h5>
           
            <p className="mb-3 font-normal">
              Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.
            </p>
            <a
              href="/newsdetails"
              className="inline-flex items-center px-3 py-2 text-sm font-medium text-center  border-2 border-white rounded-lg bor focus:ring-4 focus:outline-none "
            >
              Read more
              <svg
                className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </a>
          </div>
          </a>
        </div>

        <div className="max-w-sm bg-white border text-black  border-gray-200 shadow  rounded-2xl hover:bg-gradient-to-b from-[#002366] to-[#590929] hover:text-white">
       
            <img className="rounded-t-lg" src={news} alt="" />
        <a href='/newsdetails'>
          <div className="p-5 ">
       
              <h5 className="mb-2 text-2xl font-bold tracking-tight  ">
                New plugins for creating new charts in dashboard
              </h5>
           
            <p className="mb-3 font-normal">
              Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.
            </p>
            <a
              href="/newsdetails"
              className="inline-flex items-center px-3 py-2 text-sm font-medium text-center  border-2 border-white rounded-lg bor focus:ring-4 focus:outline-none "
            >
              Read more
              <svg
                className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </a>
          </div>
          </a>
        </div>

        <div className="max-w-sm bg-white border text-black  border-gray-200 shadow  rounded-2xl hover:bg-gradient-to-b from-[#002366] to-[#590929] hover:text-white">
       
            <img className="rounded-t-lg" src={news} alt="" />
        <a href='/newsdetails'>
          <div className="p-5 ">
       
              <h5 className="mb-2 text-2xl font-bold tracking-tight  ">
                New plugins for creating new charts in dashboard
              </h5>
           
            <p className="mb-3 font-normal">
              Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.
            </p>
            <a
              href="/newsdetails"
              className="inline-flex items-center px-3 py-2 text-sm font-medium text-center  border-2 border-white rounded-lg bor focus:ring-4 focus:outline-none "
            >
              Read more
              <svg
                className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </a>
          </div>
          </a>
        </div>

        <div className="max-w-sm bg-white border text-black  border-gray-200 shadow  rounded-2xl hover:bg-gradient-to-b from-[#002366] to-[#590929] hover:text-white">
       
            <img className="rounded-t-lg" src={news} alt="" />
        <a href='/newsdetails'>
          <div className="p-5 ">
       
              <h5 className="mb-2 text-2xl font-bold tracking-tight  ">
                New plugins for creating new charts in dashboard
              </h5>
           
            <p className="mb-3 font-normal">
              Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.
            </p>
            <a
              href="/newsdetails"
              className="inline-flex items-center px-3 py-2 text-sm font-medium text-center  border-2 border-white rounded-lg bor focus:ring-4 focus:outline-none "
            >
              Read more
              <svg
                className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </a>
          </div>
          </a>
        </div>

        <div className="max-w-sm bg-white border text-black  border-gray-200 shadow  rounded-2xl hover:bg-gradient-to-b from-[#002366] to-[#590929] hover:text-white">
       
            <img className="rounded-t-lg" src={news} alt="" />
        <a href='/newsdetails'>
          <div className="p-5 ">
       
              <h5 className="mb-2 text-2xl font-bold tracking-tight  ">
                New plugins for creating new charts in dashboard
              </h5>
           
            <p className="mb-3 font-normal">
              Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.
            </p>
            <a
              href="/newsdetails"
              className="inline-flex items-center px-3 py-2 text-sm font-medium text-center  border-2 border-white rounded-lg bor focus:ring-4 focus:outline-none "
            >
              Read more
              <svg
                className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </a>
          </div>
          </a>
        </div>

        
      </div>
    </div>
  );
}

export default Newscard;
