import React from "react";
import phone from "../../../../assets/phone.png";
import bg from "../../../../assets/Taj.png";
import bg1 from "../../../../assets/phone1.png";
import img from "../../../../assets/graphic.jpg";
import img2 from "../../../../assets/img2.png";
import img3 from "../../../../assets/img3.png";

const Graphics = () => {
  return (
    <div className="mx-5 md:mx-10 pt-10 gap-5 md:py-20 flex flex-col-reverse md:flex-row">
      <div className="flex-1 md:w-[60%]">
        <div className="bg- lg:w-[60%] mx-5 md:mx-0  rounded-2xl border-black border-2">
          <div className="bg-[#002366] text-white text-center rounded-t-2xl py-2 ">
            Project Information
          </div>
          <div className="text-black">
            <h2 className="mx-3 font-bold py-4">Features</h2>

            <li className="mx-4">Striking graphics</li>
            <li className="mx-4">Creative and stunning visuals</li>
            <li className="mx-4">Balanced sizing and vibrant color</li>
            <li className="mx-4">Expand your global reach</li>
            

            <h3 className="mx-3 font-bold py-6">Target Users</h3>
            <p className="mx-4">
            Everyone’s imagination deserves the spotlight...We let their concept shine

            </p>
          </div>
        </div>

        <div className="py-10">
          <div
            className="lg:w-[40%] mx-5 md:mx-0 rounded-2xl py-10 px-5"
            style={{
              backgroundImage: `url(${bg1})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="bg-white rounded-3xl text-black flex gap-5">
              <div className="py-4">
                <img src={phone} alt="" />
              </div>
              <div className="text-[12px] py-5">
                <h2>Have Any Questions?</h2> +91 7045 724 027
              </div>
            </div>
          </div>
        </div>

        <div className="py-10">
          <div className="bg-[#002366] lg:w-[60%] mx-5 md:mx-0 text-white text-center rounded-2xl">
            <h2 className="py-5 font-bold">Send Us Mail</h2>
            <p className="px-5 text-[16px]">
              K2K offers a range of Technology services for all firms all over
              the industry
            </p>
            <form className="px-7">
              <div className="py-4">
                <h2 className="text-start">Name</h2>
                <input
                  type="text"
                  placeholder="Enter your name"
                  className="w-full px-2 text-black flex justify-start bg-white py-1 rounded-lg"
                />
              </div>

              <div className="py-4">
                <h2 className="text-start">Email</h2>
                <input
                  type="email"
                  placeholder="Enter your email"
                  className="w-full px-2 text-black flex justify-start bg-white py-1 rounded-lg"
                />
              </div>

              <div className="py-4">
                <h2 className="text-start">Description</h2>
                <textarea
                  placeholder="Enter a description"
                  className="w-full px-2 text-black flex justify-start bg-white py-1 rounded-lg"
                  rows="4"
                />
              </div>

              <div className="py-4">
                <button
                  type="submit"
                  className="bg-white hover:bg-[#093b99] text-black hover:text-white rounded-lg font-bold py-2 px-4"
                >
                  Send Mail
                </button>
              </div>
            </form>
          </div>

          <div className="py-10">
            <div
              className="px-5 text-[16px] py-10 bg-[#002366] lg:w-[60%] mx-5 md:mx-0 text-white rounded-2xl"
              style={{
                backgroundImage: `url(${bg})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <p className="w-[80%] mx-auto">
                Office No. 205, 2nd floor, Shree Amar heights, Plot no. E 10 &
                33 DCM Ajmer Road, 302006, Jaipur
              </p>
              <br />
              <p className="mx-auto w-[80%]">
                Phone: <span className="text-[#EC9422]">+91 7045 724 027</span>{" "}
                Email:{" "}
                <span className="text-[#EC9422]">info@k2kit support.com</span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex-1 text-black">
        <div>
          <h2 className="py-5 text-[35px] font-bold">Graphics</h2>
          <p className="py-5">
          In the realm of technology, programming, computing, and communications; graphics play a vital role 
in user interfaces, digital art, gaming, web design, and many more fields.
            <br />
            Graphics are an artistic visual way to communicate your thoughts, ideas, information, illustration, or 
enhance the visual appeal of various forms of media effectively.
          </p>
          <span className="text-[#EC9422]">
            “Why blend in when you can stand out? Transform Your Concepts into Captivating Designs...”.
          </span>

          <img src={img} alt="" className="w-full rounded-lg" />

          <p className="font-bold py-5">Promising features of our e-commerce platform</p>
          <ul className="list-disc list-inside">
            <li className="py-2">
            Our designs speak louder than words.
            </li>
            <li className="py-2">
            Minimalistic and elegant to bold and dynamic designs
            </li>
            <li className="py-2">
            Advanced graphic design software to ensure innovation and precision.
            </li>
            <li className="py-2">
            Adherence to deadlines without compromising on quality.
            </li>
            <li className="py-2">
            Providing designs in formats suited for diverse platforms.
            </li>
          </ul>

          <div className="flex gap-10 py-10">
            <img src={img2} alt="" className="w-1/2 rounded-lg" />
            <img src={img3} alt="" className="w-1/2 rounded-lg" />
          </div>

          <p className="py-2">
          Our designers give a creative touch to your thoughts and make unique designs. It could be in the 
form of brochures, banners, pamphlets, logos, visiting cards, etc. The designer’s team transforms 
your ideas, needs, thoughts, and suggestions into realism. 
          </p>
          <p className="py-2">
          We understand and respect your desire. As per your requirements, we design and give life to your 
          ideas to enhance user experience and effective communication of information.
          </p>

          <p className="py-2">
            To get detailed information about the software, book a demo or
            contact us at{" "}
            <span className="font-bold">k2kit.info@gmail.com</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Graphics;