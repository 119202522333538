import React from "react";
import logo1 from "../../../assets/erp.png";
import logo2 from "../../../assets/social.jpg";
import logo3 from "../../../assets/e-commerce.jpg"
import logo4 from "../../../assets/mobile Chat app.jpg"
import logo5 from "../../../assets/website.jpg"
import logo6 from "../../../assets/graphic.jpg"

const Cards = () => {
  return (
    <div className="mx-5 py-20 flex justify-center">
      <div className="grid lg:grid-cols-3 gap-10">
        
        <div className="max-w-sm bg-white border border-gray-200 rounded-lg shadow">
         
            <img
              className="rounded-t-lg px-5  pt-4"
              src={logo1}
              alt="Software Development"
            />
        <a href="/schoolerp">
          <div className="p-5 text-white rounded-2xl bg-[#002366]">
         
            
              <h5 className="mb-2 text-2xl font-bold tracking-tight ">
                K2K School Erp
              </h5>
          
            
            <p className="mb-3 font-normal ">
             A Personalized school management system for Schools
            </p>
           
          </div>
          
        </a>
        </div>
        <div className="max-w-sm bg-white border border-gray-200 rounded-lg shadow">
         
            <img
              className="rounded-t-lg px-5  pt-4"
              src={logo2}
              alt="Software Development"
            />
         <a href="/socialmedia">
          <div className="p-5 text-white rounded-2xl bg-[#002366]">
          
              <h5 className="mb-2 text-2xl font-bold tracking-tight ">
                Social Media App
              </h5>
         
            <p className="mb-3 font-normal ">
              A Personalized Social Media app for The Growth Of your Business
            </p>
          </div>
          </a>
        </div>

        <div className="max-w-sm bg-white border border-gray-200 rounded-lg shadow ">
       
            <img
              className="rounded-t-lg px-5  pt-4"
              src={logo3}
              alt="Software Development"
            />
            <a href="/ecommerce">
          <div className="p-5 text-white rounded-2xl bg-[#002366]">
           
              <h5 className="mb-2 text-2xl font-bold tracking-tight ">
                E-commerce
              </h5>
      
            <p className="mb-3 font-normal ">
              A Personalized Ecommerce for making your Store Online
            </p>
          </div>
          </a>
        </div>

        <div className="max-w-sm bg-white border border-gray-200 rounded-lg shadow">
        
            <img
              className="rounded-t-lg px-5  pt-4"
              src={logo4}
              alt="Software Development"
            />
            <a href="/chatapp">
          <div className="p-5 text-white rounded-2xl bg-[#002366]">
       
              <h5 className="mb-2 text-2xl font-bold tracking-tight ">
                Chat App
              </h5>
        
            <p className="mb-3 font-normal ">
              Secure Chat Application With Top notch Security
            </p>
          </div>
          </a>
        </div>

        <div className="max-w-sm bg-white border border-gray-200 rounded-lg shadow">
      
            <img
              className="rounded-t-lg px-5  pt-4"
              src={logo5}
              alt="Software Development"
            />
            <a href="/website">
          <div className="p-5 text-white rounded-2xl bg-[#002366]">
           
              <h5 className="mb-2 text-2xl font-bold tracking-tight ">
               Personalized Websites
              </h5>
          
            <p className="mb-3 font-normal ">
              Get Your Own Personalized Website for the Growth Of your Business
            </p>
          </div>
          </a>
        </div>

        <div className="max-w-sm bg-white border border-gray-200 rounded-lg shadow">
            <img
              className="rounded-t-lg px-5  pt-4"
              src={logo6}
              alt="Software Development"
            />
            <a href="/graphics">
          <div className="p-5 text-white rounded-2xl bg-[#002366]">
            
              <h5 className="mb-2 text-2xl font-bold tracking-tight ">
                Graphics
              </h5>
            <p className="mb-3 font-normal ">
             Showcasing Your Business in the form of Graphics
            </p>
          </div>
          </a>
        </div>
      </div>
      
    </div>
  );
};

export default Cards;
