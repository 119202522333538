import React from 'react'
import phone from "../../../../assets/phone.png";
import bg from "../../../../assets/Taj.png";
import bg1 from "../../../../assets/phone1.png";
import img from "../../../../assets/erp.png";
import img2 from "../../../../assets/img2.png";
import img3 from "../../../../assets/img3.png";

const Schoolerp = () => {
  

  
  return (
    <div className="mx-5 md:mx-10 pt-10 gap-5 md:py-20 flex flex-col-reverse md:flex-row">
      <div className="flex-1 md:w-[60%]">
      <div className="bg- lg:w-[60%] mx-5 md:mx-0  rounded-2xl border-black border-2">
      <div className='bg-[#002366] text-white text-center rounded-t-2xl py-2 '>
        Project Information
      </div>
      <div className='text-black'>
        <h2 className='mx-3 font-bold py-4'>Features</h2>
        
            <li className='mx-4'>Attendance tracking and management</li>
            <li className='mx-4'>Fee collection and accounting integration</li>
            <li className='mx-4'>Timetable and exam scheduling</li>
            <li className='mx-4'>Activities and announcements automation</li>
            <li className='mx-4'>Library, Transportation management</li>
            <li className='mx-4'>Integrated messaging system for seamless communication</li>

            <h3 className='mx-3 font-bold py-6'>Target Users</h3>
            <p className='mx-4'>School administrators, educators, students, and parents seeking efficient management and communication tools.</p>
            {/* <h4 className='mx-3 font-bold py-5'>Estimated Pricing</h4>
            <p className='mx-3 text-[#EC9422] font-bold pb-10'>₹50000 <s className='text-black font-normal mx-5'>₹70000</s></p> */}
      </div>
    </div>

        <div className="py-10">
          <div
            className="lg:w-[40%] mx-5 md:mx-0 rounded-2xl py-10 px-5"
            style={{
              backgroundImage: `url(${bg1})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          >
            <div className="bg-white rounded-3xl text-black flex gap-5">
              <div className="py-4">
                <img src={phone} alt="" />
              </div>
              <div className="text-[12px] py-5">
                <h2>Have Any Questions?</h2> +91 7045 724 027
              </div>
            </div>
          </div>
        </div>

        <div className="py-10">
          <div className="bg-[#002366] lg:w-[60%] mx-5 md:mx-0 text-white text-center rounded-2xl">
            <h2 className="py-5 font-bold">Send Us Mail</h2>
            <p className="px-5 text-[16px]">
              K2K offers a range of Technology services for all firms all over the industry
            </p>
            <form className="px-7">
              <div className="py-4">
                <h2 className="text-start">Name</h2>
                <input
                  type="text"
                  placeholder="Enter your name"
                  className="w-full px-2 text-black flex justify-start bg-white py-1 rounded-lg"
                />
              </div>

              <div className="py-4">
                <h2 className="text-start">Email</h2>
                <input
                  type="email"
                  placeholder="Enter your email"
                  className="w-full px-2 text-black flex justify-start bg-white py-1 rounded-lg"
                />
              </div>

              <div className="py-4">
                <h2 className="text-start">Description</h2>
                <textarea
                  placeholder="Enter a description"
                  className="w-full px-2 text-black flex justify-start bg-white py-1 rounded-lg"
                  rows="4"
                />
              </div>

              <div className="py-4">
                <button
                  type="submit"
                  className="bg-white hover:bg-[#093b99] text-black hover:text-white rounded-lg font-bold py-2 px-4"
                >
                  Send Mail
                </button>
              </div>
            </form>
          </div>
          
          <div className="py-10">
            <div
              className="px-5 text-[16px] py-10 bg-[#002366] lg:w-[60%] mx-5 md:mx-0 text-white rounded-2xl"
              style={{
                backgroundImage: `url(${bg})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            >
              <p className="w-[80%] mx-auto">
                Office No. 205, 2nd floor, Shree Amar heights, Plot no. E 10 & 33 DCM Ajmer Road, 302006, Jaipur
              </p>
              <br />
              <p className="mx-auto w-[80%]">
                Phone: <span className="text-[#EC9422]">+91 7045 724 027</span> Email: <span className="text-[#EC9422]">info@k2kit support.com</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      
      <div className="flex-1 text-black">
        <div>
          <h2 className="py-5 text-[35px] font-bold">School Erp</h2>
          <p className="py-5">
          Education is increasingly digitizing and to gain an advantage, it becomes necessary to implement a 
robust school ERP system to streamline operations, enhance communication, and ultimately 
contribute to better educational outcomes. 
<br/>
Our School ERP Software is designed to centralize data, automate processes, and provide real-time 
insights, empowering schools to focus on what truly matters:
          </p>
          <span className="text-[#EC9422]">“Nurturing student growth and academic excellence”.</span>
          
          
          <img src={img} alt="" className="w-full rounded-lg" />
          <h3 className="py-5 text-[19px] font-bold">With seamless integration of various aspects of school, our robust software provides one powerful 
          platform solution for School management, teachers, students, and parents. </h3>
          <p>Promising features of our school ERP software:</p>
          <ul className="list-disc list-inside">
            <li className="py-2">Effectively manages and integrates all administrative and non-administrative tasks of a school</li>
            <li className="py-2">Its layout has been designed and developed as fully responsive and smooth, automatically 
            adjusting and adapting to any screen size, browser, and operating system </li>
            <li className="py-2">Modules are interlinked and customized according to the requirements of the school.</li>
            
          </ul>
          
          <div className="flex gap-10 py-10">
            <img src={img2} alt="" className="w-1/2 rounded-lg" />
            <img src={img3} alt="" className="w-1/2 rounded-lg" />
          </div>

          <p className="py-2">
          Our software is a smart solution that enables the schools to conduct and manage all the daily 
activities like attendance management, student information, fee tracking, staff salary, administrative 
tasks, announcements, class activities, timetable planning, homework management, library records, 
etc.
          </p>
          <p className="py-2">
          In addition to these services, we excel in business process automation, automating repetitive and time-consuming tasks to improve efficiency and reduce human error. 

          </p>

          <p className='py-2 font-bold'>
          *Parents/guardians oversee the performance of their ward.
          </p>
          <p className='py-2'>
          To get detailed information about the software, book a demo or contact us at 	<span className='font-bold'>k2kit.info@gmail.com</span>	
          </p>
        </div>
      </div>
    </div>
  )
}

export default Schoolerp