import React from 'react'
import phone from "../../../../assets/phone.png";
import bg from "../../../../assets/Taj.png";
import bg1 from "../../../../assets/phone1.png";
import img from "../../../../assets/social.jpg";
import img2 from "../../../../assets/img2.png";
import img3 from "../../../../assets/img3.png";

const Socialmedia = () => {
  

  
  return (
    <div className="mx-5  md:mx-10 pt-10 gap-5 md:py-20 flex flex-col-reverse md:flex-row">
      <div className="flex-1 md:w-[60%]">
      <div className="bg- lg:w-[60%] mx-5 md:mx-0  rounded-2xl border-black border-2">
      <div className='bg-[#002366] text-white text-center rounded-t-2xl py-2 '>
        Project Information
      </div>
      <div className='text-black'>
        <h2 className='mx-3 font-bold py-4'>Features</h2>
        
            <li className='mx-4'>Access to post reels, recipes, blogs, any related events, news, and many more </li>
            <li className='mx-4'>Individual/ group chat</li>
            <li className='mx-4'>End-to-end encryption for private messages</li>
            <li className='mx-4'>AR filters and effects</li>
            <li className='mx-4'>Audio/video individual as well as group calls</li>

            <h3 className='mx-3 font-bold py-6'>Target Users</h3>
            <p className='mx-4'>Connect, create, and inspire—your story deserves to be shared after the age of 18.
            </p>
        
      </div>
    </div>

        <div className="py-10">
          <div
            className="lg:w-[40%] mx-5 md:mx-0 rounded-2xl py-10 px-5"
            style={{
              backgroundImage: `url(${bg1})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          >
            <div className="bg-white rounded-3xl text-black flex gap-5">
              <div className="py-4">
                <img src={phone} alt="" />
              </div>
              <div className="text-[12px] py-5">
                <h2>Have Any Questions?</h2> +91 7045 724 027
              </div>
            </div>
          </div>
        </div>

        <div className="py-10">
          <div className="bg-[#002366] lg:w-[60%] mx-5 md:mx-0 text-white text-center rounded-2xl">
            <h2 className="py-5 font-bold">Send Us Mail</h2>
            <p className="px-5 text-[16px]">
              K2K offers a range of Technology services for all firms all over the industry
            </p>
            <form className="px-7">
              <div className="py-4">
                <h2 className="text-start">Name</h2>
                <input
                  type="text"
                  placeholder="Enter your name"
                  className="w-full px-2 text-black flex justify-start bg-white py-1 rounded-lg"
                />
              </div>

              <div className="py-4">
                <h2 className="text-start">Email</h2>
                <input
                  type="email"
                  placeholder="Enter your email"
                  className="w-full px-2 text-black flex justify-start bg-white py-1 rounded-lg"
                />
              </div>

              <div className="py-4">
                <h2 className="text-start">Description</h2>
                <textarea
                  placeholder="Enter a description"
                  className="w-full px-2 text-black flex justify-start bg-white py-1 rounded-lg"
                  rows="4"
                />
              </div>

              <div className="py-4">
                <button
                  type="submit"
                  className="bg-white hover:bg-[#093b99] text-black hover:text-white rounded-lg font-bold py-2 px-4"
                >
                  Send Mail
                </button>
              </div>
            </form>
          </div>
          
          <div className="py-10">
            <div
              className="px-5 text-[16px] py-10 bg-[#002366] lg:w-[60%] mx-5 md:mx-0 text-white rounded-2xl"
              style={{
                backgroundImage: `url(${bg})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            >
              <p className="w-[80%] mx-auto">
                Office No. 205, 2nd floor, Shree Amar heights, Plot no. E 10 & 33 DCM Ajmer Road, 302006, Jaipur
              </p>
              <br />
              <p className="mx-auto w-[80%]">
                Phone: <span className="text-[#EC9422]">+91 7045 724 027</span> Email: <span className="text-[#EC9422]">info@k2kit support.com</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      
      <div className="flex-1 text-black">
        <div>
          <h2 className="py-5 text-[35px] font-bold">Social Media App</h2>
          <p className="py-5">
          In a world of endless scrolling and superficial likes, transform the way you share your world with our 
social media platform that puts authentic connections first. 

<br/>
Our platform helps you build meaningful connections that last. It emphasizes quality over quantity, 
ensuring your social media experience feels more personal and purposeful. It enables users to 
create, share, and interact with content and other users online. It includes features such as profiles, 
news feeds, messaging, photo and video sharing, and community building.
          </p>
          <span className="text-[#EC9422]">“Your Story, Your Way... Connect deeper and flow further with our tailored social media
          application....”</span>
          
          
          <img src={img} alt="" className="w-full rounded-lg" />
         
          <p className='font-bold py-5'>Promising features of our Social Media App:</p>
          <ul className="list-disc list-inside">
            <li className="py-2">Find like-minded individuals through shared interests and experiences</li>
            <li className="py-2">Creates multimedia stories combining photos, videos, and audio </li>
            <li className="py-2">Allows real-time updates and live-streaming capabilities</li>
            <li className='py-2'>Industry-leading granular privacy settings for each post</li>
            
          </ul>
          
          <div className="flex gap-10 py-10">
            <img src={img2} alt="" className="w-1/2 rounded-lg" />
            <img src={img3} alt="" className="w-1/2 rounded-lg" />
          </div>

          <p className="py-2">
          Highlighting ethical concerns and lessening the human ill-impact on the environment, our easy-to-
use social media platform promotes sustainability and harmony on the planet. It’s a great stage for 
taking a step ahead to make a greener and kinder earth for all inhabitants.
          </p>
          <p className="py-2">
          Our customized social media platform is both iOS and Android supportive. Flourish your true 
relationship with our platform and turn personal moments into shared experiences.
          </p>

         
          <p className='py-2'>
          To get detailed information about the software, book a demo or contact us at 	<span className='font-bold'>k2kit.info@gmail.com</span>	
          </p>
        </div>
      </div>
    </div>
  )
}

export default Socialmedia